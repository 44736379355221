/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class EditCraft extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  componentDidMount(){
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

    console.log(lastSegment);
    document.querySelector('#id').value = lastSegment;

    const apiKey = window.localStorage.getItem('lumokey');
        if(apiKey){
          axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
          }}).then((response, err) => {
            if(response.data.role != "admin"){
              this.props.history.push('/');
            }
            this.setState({ user: response.data})
            axios.get('https://api.playpanzer.com/lmcraft/' + lastSegment, {
              mode: 'no-cors', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiKey}`
              }}).then((data) => {
                const npcData = data.data;

                document.querySelector('#id').value = npcData.id;
                document.querySelector('#slug').value = npcData.slug;
                document.querySelector('#name').value = npcData.name;
                document.querySelector('#description').value = npcData.description;
                document.querySelector('#items').value = npcData.items;

                this.setState({ data: npcData });
            }).catch((error)=>{
              return error;
            });
          }).catch(()=>{
              return;
          });
      } else {
          this.props.history.push('/login');
      }
  }

  createProduct = () => {
    var formData = new FormData();

    formData.append("id", document.querySelector('#id').value);
    formData.append("slug", document.querySelector('#slug').value);
    formData.append("name", document.querySelector('#name').value);
    formData.append("description", document.querySelector('#description').value);
    formData.append("items", document.querySelector('#items').value);

    axios.post('https://api.playpanzer.com/lumo/edit-craft', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    window.location.replace("/admin/crafts");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <br /><label>ID</label><br /><input className="h2input" id="id" />
          <br /><label>Slug</label><br /><input className="h2input" id="slug" />
          <br /><label>Name</label><br /><input className="h2input" id="name" />
          <br /><label>Description</label><br /><input className="h2input" id="description" />
          <br /><label>Items</label><br /><input className="h2input" id="items" />
          <br></br>
          <button onClick={() => this.createProduct()}  className="button">Update Craft</button>
        </div>
        <br /><br /><br />
    </div>)
  }
}
