import React from 'react';
import { NavLink } from 'react-router-dom';
import Masonry from 'react-masonry-css'

import axios from 'axios';

export default class Workshop extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user: null,
        searchTerm: null
      }

      this.search = this.search.bind(this);
    }

    componentDidMount(){
      const apiKey = window.localStorage.getItem('lumokey');
      axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${apiKey}`
        }}).then((response) => {
          axios.get('https://api.playpanzer.com/lmmaps', {
            mode: 'no-cors', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiKey}`
          }}).then((workshop) => {
            this.setState({ user: response.data, data: workshop.data })
          });
      }).catch((error)=>{
        return error;
      });
    }

    search(event) {
      const searchTerm = event.target.value;

      const apiKey = window.localStorage.getItem('lumokey');
      axios.get(`https://api.playpanzer.com/lumo/search/workshop/${searchTerm}`, {
        mode: 'no-cors', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${apiKey}`
      }}).then((workshop) => {
        this.setState({ searchTerm, data: workshop.data.data })
      });
    }

    render(){
      var results = [];

      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <NavLink to={'/project/' + data._id}>
              <div className="workshop-item" style={data.cover ? { backgroundImage: `url(${data.cover})` } : null}>
                <h1 className="workshop-name">{data.seed}</h1>
                <h5 className="workshop-tag">{data.mode}</h5>
                <div className="workshop-visits">{data.visits}</div>
              </div>
            </NavLink>
          );
        });
      }

      if(this.state.user){
        // console.log(this.state.billboard);
        return(
          <div className="workshop">
            <input className="search-input center" placeholder="Search Workshop" value={this.state.searchTerm} onChange={this.search}></input>

            <div className="workshop-body">
              <Masonry
                breakpointCols={4}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {results}
              </Masonry>
            </div>
          </div>
        );
      }
      return(
        <div></div>
      );
    }
}
