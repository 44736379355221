import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../styles/style.scss';

// Since this component is simple and static, there's no parent container for it.

export default class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: null,
      code: false,
      email: null,
      status: null
    }

    this.loginRequest = this.loginRequest.bind(this);
    this.codeRequest = this.codeRequest.bind(this);
  }

  componentDidMount(){
    const apiKey = window.localStorage.getItem('lumokey');
    if(apiKey){
      axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${apiKey}`
        }}).then((response, err) => {
          if(err){
            return;
          } else {
            // this.props.history.push('/dashboard');
            // location.reload();
          }
          this.setState({ user: response.data})
      }).catch(()=>{
        return;
      });
    } else {
      this.props.history.push('/login');
    }
  }


  loginRequest = () => {
    console.log("login request");
    const code =  document.querySelector('#code').value;

    axios.get(`https://api.playpanzer.com/panzer/login/${this.state.email}/${code}`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      window.localStorage.setItem('lumokey', response.data.key);
      this.props.history.push('/dashboard');
      location.reload();
    }).catch((error)=>{
      return error;
    });
    // REQUEST
  }

  codeRequest = () => {
    console.log("code request");
    const email = document.querySelector('#email').value;

    axios.get(`https://api.playpanzer.com/panzer/code/${email}`, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      this.setState({ code: true, email, status: response.data.success });
    });

    document.querySelector('#email').value = "";
  }

  render(){
    return (
      <div className="innerBody">
        <h2>Log in</h2>
        <h3>We dislike traditional passwords. <br/> For extra security, we've replaced them with two-step email verification (aka passwordless login).</h3>
        {/* <h3>We dislike traditional passwords. <br/> For extra security, we've replaced them with two-step email verification (aka passwordless login).</h3> */}
        <br /><br />
        { this.state.status == 'true' ? (
          <div className="information">
            Check your email inbox
          </div>
        ) : this.state.status == 'false' ? (
          <div className="information">
            This email address is not registered
          </div>
        ) : null}
        <br />
        { this.state.code && this.state.status == 'true' ? (
          <div className="authBody">
            <input id="code" className="authForm" placeholder="Verification code" size="30" name="code" aria-required="true"></input>
            {/* <br /> */}
            <button className="authButton" onClick={() => this.loginRequest()}>Sign in</button>
          </div>
        ) : (
          <div className="authBody">
            <input id="email" className="authForm" placeholder="Your email address" type="email" size="30" name="email" aria-required="true"></input>
            {/* <br /> */}
            <button className="authButton" onClick={() => this.codeRequest()}>Request code</button>
          </div>
        )}
      </div>
    );
  }
}


Login.propTypes = {
  history: PropTypes.object,
  loginRequest: PropTypes.func,
  codeRequest: PropTypes.func,
};
