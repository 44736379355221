import React from 'react';
import axios from 'axios';
// import 'react-datasheet/lib/react-datasheet.css';
import { differenceInDays } from 'date-fns';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

export default class UserAdminDashboard extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user : null,
      }
    }

    componentDidMount(){
      var parts = window.location.href.split('/');
      var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

      console.log(lastSegment);
      document.querySelector('#id').value = lastSegment;

      const apiKey = window.localStorage.getItem('lumokey');
          if(apiKey){
            axios.get('https://api.playpanzer.com/panzer/user/info', {
            mode: 'no-cors', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiKey}`
            }}).then((response, err) => {
              if(response.data.role != "admin"){
                this.props.history.push('/');
              }
              this.setState({ user: response.data})
              axios.get('https://api.playpanzer.com/lmuser/' + lastSegment, {
                mode: 'no-cors', headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `${apiKey}`
                }}).then((data) => {
                  const npcData = data.data;

                  document.querySelector('#id').value = npcData.id;
                  document.querySelector('#name').value = npcData.username;
                  document.querySelector('#level').value = npcData.level;
                  document.querySelector('#tickets').value = npcData.tickets;
                  document.querySelector('#xp').value = npcData.xp;
                  document.querySelector('#balance').value = npcData.balance;
                  document.querySelector('#lifetime_balance').value = npcData.lifetime_balance;

                  this.setState({ data: npcData });
              }).catch((error)=>{
                return error;
              });
            }).catch(()=>{
                return;
            });
        } else {
            this.props.history.push('/login');
        }
    }

    createProduct = () => {
      var formData = new FormData();

      formData.append("id", document.querySelector('#id').value);
      formData.append("name", document.querySelector('#name').value);
      formData.append("tickets", document.querySelector('#tickets').value);
      formData.append("balance", document.querySelector('#balance').value);
      formData.append("lifetime_balance", document.querySelector('#lifetime_balance').value);
      formData.append("level", document.querySelector('#level').value);
      formData.append("xp", document.querySelector('#xp').value);


      axios.post('https://api.playpanzer.com/lumo/update-user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });

      window.location.replace("/admin/users");
    }

    render () {
      return(
        <div className="innerBody">
          <div className="card">
            <br /><label>ID</label><br /><input className="h2input" id="id" />
            <br /><label>Username</label><br /><input className="h2input" id="name" />
            <br /><label>Level</label><br /><input className="h2input" id="level" />
            <br /><label>XP</label><br /><input className="h2input" id="xp" />
            <br /><label>Tickets</label><br /><input className="h2input" id="tickets" />
            <br /><label>Balance</label><br /><input className="h2input" id="balance" />
            <br /><label>Lifetime Balance</label><br /><input className="h2input" id="lifetime_balance" />
            <br />
            <br></br>
            <button onClick={() => this.createProduct()}  className="button">Update User</button>
          </div>
          <br /><br /><br />
      </div>)
    }
}
