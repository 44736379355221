/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class EditMob extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  createProduct = () => {
    var formData = new FormData();

    formData.append("id", document.querySelector('#id').value);
    formData.append("name", document.querySelector('#name').value);
    formData.append("level", document.querySelector('#level').value);
    formData.append("type", document.querySelector('#type').value);
    formData.append("model", document.querySelector('#model').value);
    formData.append("location", document.querySelector('#location').value);
    formData.append("xp", document.querySelector('#xp').value);
    formData.append("skill", document.querySelector('#skill').value);
    formData.append("data", document.querySelector('#data').value);

    axios.post('https://api.playpanzer.com/lumo/update-gnode', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    window.location.replace("/admin/npcs");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <br /><label>ID</label><br /><br /><input className="h2input" id="id" />
          <br /><label>Name</label><br /><br /><input className="h2input" id="name" />
          <br /><label>Level</label><br /><br /><input className="h2input" id="level" />
          <br /><label>Type</label><br /><br /><input className="h2input" id="type" />
          <br /><label>Model</label><br /><br /><input className="h2input" id="model" />
          <br /><label>Location</label><br /><br /><input className="h2input" id="location" />
          <br /><label>XP</label><br /><br /><input className="h2input" id="xp" />
          <br /><label>Skill</label><br /><br /><input className="h2input" id="skill" />
          <br /><label>Data</label><br /><br /><input className="h2input" id="data" />
          <br></br>
          <button onClick={() => this.createProduct()}  className="button">Update Node</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
