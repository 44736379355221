import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import Topbar from './Topbar';

export default class Home extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: null,
      events: [],
      waitlisted: false
    }
  }

  waitlist = () => {
    var formData = new FormData();

    formData.append("email", document.querySelector('#emailInput').value);

    axios.post('https://api.playpanzer.com/lumo/waitlist', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    // window.location.replace("/");
    this.setState({ waitlisted: true });
  }


  componentDidMount(){
    const apiKey = window.localStorage.getItem('lumokey');
    if(apiKey){
      axios.get('https://api.playpanzer.com/panzer/user/info', {
      mode: 'no-cors', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${apiKey}`
      }}).then((response, err) => {
        this.setState({ user: response.data });
      }).catch(()=>{
          return;
      });
    }
  }

  render(){
    return (
      <div>
        <div className="homeColumn">
          <div className="side">
            {/* <img src="./updog_wordmark.png" className="elsewhere_icon" /> */}
            <h2>What's <span className="rainbow-energetic">Updog?</span><span className="eyes">👀</span></h2>
            <p className="desc">
              Updog is a bootstrapped game studio working on experimental complex games.
              <br /><br />
              <span className="white">We're currently working on a quick-fire, quirky chess roguelike called '<b>rook</b>', inspired by games like WarioWare & Balatro.</span>
              <br /><br />
              Lose yourself in the cascading mayhem as your strategies break the rules of chess in satisfying ways round after round.
              <br /><br />

              <a href="https://twitter.com/gregiselsewhere">
                <div className="twitter-container">
                  <span className="twitter-underline">Follow along with development on Twitter 📪</span>
                </div>
              </a>
              <br />
              Our core project is PANZER, a sandbox MMORPG set in a gritty expansive sci-fi universe, driven and shaped by its community.
              <br /><br />
              It's a living world with an evolving narrative that you're invited to participate in; explore the universe of PANZER, its lore, its community, play however you like.
              <br /><br />
              {this.state.waitlisted ? (
                <div>
                  <h2>You're on the waitlist!</h2>
                </div>
              ) : (
                <div>
                  <h2>Join the club</h2>
                  <p className="desc">
                    If you're interested in what we're cooking, you can sign up to let us email you when we have something to say.
                    <br /><br />
                    You'll also receive an exclusive status in our Discord community.
                  </p>
                  <form onSubmit={() => this.waitlist()}>
                    <input className="waitlistInput" placeholder="updog@hey.com" type="email" id="emailInput" />
                    <button className="waitlistButton" type="submit">UPDATE ME</button>
                  </form>
                </div>
              )}
            </p>

            <button className="contact-color" onClick={() => location.href = "mailto:updog@hey.com"} type="submit">CONTACT/HIRE US</button>
            <button className="discord-color" onClick={() => location.href = "https://discord.gg/dfXFmD6"} type="submit">JOIN OUR DISCORD</button>
            <button onClick={() => location.href = "https://playpanzer.com"} type="submit">LEARN MORE ABOUT PANZER</button>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  history: PropTypes.object,
};
