import React from 'react';
import axios from 'axios';
// import 'react-datasheet/lib/react-datasheet.css';
import { Eye } from 'react-feather';

export default class UserAdminDashboard extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user: null,
        views: null
      }
    }

    componentDidMount(){

      const apiKey = window.localStorage.getItem('lumokey');
        if(apiKey){
          axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
          }}).then((response, err) => {
            if(response.data.role != "admin"){
              this.props.history.push('/');
            }
            this.setState({ user: response.data})
            axios.get('https://api.playpanzer.com/lmitemtypes', {
              mode: 'no-cors', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiKey}`
              }}).then((users) => {
                this.setState({ data: users.data });
            }).catch((error)=>{
              return error;
            });
          }).catch(()=>{
              return;
          });
      } else {
        this.props.history.push('/login');
      }
    }

    render(){
      var results = [];

      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <tr>
              <td>{data.name}</td>
              <td>{data.description}</td>
              <td>{data.slug}</td>
              <td>{data.type}</td>
              <td>{data.slot}</td>
              <td>{data.rarity}</td>
              <td>{data.stats ? data.stats : 'null'}</td>
              <td>{data.stackable ? data.stackable : 'null'}</td>
              <td>
                <a href={`/admin/edit-itemtype/${data.slug}`}>
                  <div className="viewButton">
                    <Eye color="white" size={24} />
                  </div>
                </a>
              </td>
            </tr>
          );
        });
      }

      if(this.state.user){
        return(
          <div className="innerBody">
            <div className="card">
              <h2 className="pageHeader">Item Types</h2>
              <button onClick={() => window.location.replace("/admin/create/type")}  className="button">Create Type</button>
              <br/><br/>
              <table className="users">
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Slug</th>
                  <th>Type</th>
                  <th>Slot</th>
                  <th>Rarity</th>
                  <th>Base Stats</th>
                  <th>Stackable</th>
                  <th>Actions</th>
                </tr>
                {results}
              </table>
            </div>
            <br/><br/><br/>
          </div>
        );
      }
      return(
        <div>
        </div>
      );
    }
}
