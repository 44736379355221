import React from 'react';
import axios from 'axios';
// import 'react-datasheet/lib/react-datasheet.css';
import { Eye } from 'react-feather';

export default class MobAdminDashboard extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user: null,
        views: null
      }
    }

    componentDidMount(){
      const apiKey = window.localStorage.getItem('lumokey');
        if(apiKey){
          axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
          }}).then((response, err) => {
            if(response.data.role != "admin"){
              this.props.history.push('/');
            }
            this.setState({ user: response.data})
            axios.get('https://api.playpanzer.com/lmhermes', {
              mode: 'no-cors', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiKey}`
              }}).then((users) => {
                this.setState({ data: users.data });
            }).catch((error)=>{
              return error;
            });
          }).catch(()=>{
              return;
          });
      } else {
          this.props.history.push('/login');
      }
    }

    render(){
      var results = [];

      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <tr>
              <td>{data.id}</td>
              <td>{data.name}</td>
              <td>{data.x}</td>
              <td>{data.y}</td>
              <td>{data.used}</td>
              <td>{data.created_on}</td>
              <td>
                <a style={{ display: 'inherit' }} href={`/admin/edit-hermes/${data.id}`}>
                  <div className="viewButton">
                    <Eye color="white" size={24} />
                  </div>
                </a>
              </td>
            </tr>
          );
        });
      }

      if(this.state.user){
        return(
          <div className="innerBody">
            <div className="card">
              <h2 className="pageHeader">Hermes Vendors</h2>
              <button onClick={() => window.location.replace("/admin/create/hermes")}  className="button">Create Hermes Vendor</button>
              <br/><br/>
              <table className="users">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>X</th>
                    <th>Y</th>
                    <th>Usage</th>
                    <th>Created On</th>
                    <th style={{ width: 200 }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {results}
                </tbody>
              </table>
            </div>
            <br/><br/><br/>
          </div>
        );
      }
      return(
        <div>
        </div>
      );
    }
}
