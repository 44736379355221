/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';

import { PrismicProvider } from '@prismicio/react';
import { client } from './prismic';

// import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require('./favicon.ico'); // Tell webpack to load favicon.ico
require('./puzzle.png');
require('./updog_icon.png');
require('./updog_wordmark.png');
// require('./Awasete Powder.ttf');
// require('./m6x11plus.ttf');

const store = configureStore();

render(
  <React.StrictMode>
    <PrismicProvider client={client}>
      <AppContainer>
        <Root store={store} history={history} />
      </AppContainer>
    </PrismicProvider>
  </React.StrictMode>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
