import React from 'react';
import {
  NavLink,
} from 'react-router-dom';

import axios from 'axios';

export default class Navigation extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        user: null,
        hide: false,
      }
    }

    componentDidMount(){
        // var logoArray = new Array("https://cdn.glitch.com/ee644096-ca86-4771-9da0-26bb76cd7e13%2FDoodle%202%20(2).svg?v=1601481766081",
        // "https://cdn.glitch.com/ee644096-ca86-4771-9da0-26bb76cd7e13%2FDoodle%201.svg?v=1601476673284",
        // "https://cdn.glitch.com/ee644096-ca86-4771-9da0-26bb76cd7e13%2FDoodle%203%20(1).svg?v=1601482457709");

        // var randomNum = Math.floor((Math.random() * logoArray.length));
        // document.getElementById("logo_image").src = logoArray[randomNum];


        // var subheaderArray = new Array("Polishing pixels","Charging COGS");

        // var randomSubNum = Math.floor((Math.random() * subheaderArray.length));
        // document.getElementById("subheader").innerHTML = subheaderArray[randomSubNum];
    }

    render(){
        const activeStyle = { color: '#8490FF', fontFamily: 'Visby', margin: 15, fontSize: 16, textDecoration: 'none'};
        const normalStyle = { color: '#333', fontFamily: 'Visby', margin: 15, fontSize: 16, textDecoration: 'none'};

        console.log(window.location.href);

        if(this.state.hide){
            return(
              <div style={{ display: 'block', position: 'absolute', top: '50px', }}>
                  {/* <a href="/"><img style={{ height: 50, marginTop: -10, float: 'left' }} src="https://tiny-cdn.s3-us-west-1.amazonaws.com/logos/billboard.png"></img></a> */}
              </div>
            );
        }

        if(window.location.href != 'https://playpanzer.com/'){
            return(
              <div>
                <div class="navbar center">
                  <a href="/" class="link">
                      home
                  </a>
                  <a href="https://discord.gg/dfXFmD6" class="link2 tooltip">
                      community
                  </a>
                  <a href="/leaderboards" class="link3">
                      leaderboards
                  </a>
                  <a href="/store" class="link4">
                      store
                  </a>
                  <a href="/download" class="link">
                      download
                  </a>
                </div>
              </div>
            );
        } else {
            return(
              <div>
                <div>
                  <div id="subheader" class="hide"></div>
                </div>
              </div>
            );
        }
    }
}
