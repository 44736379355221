/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class CreateGatheringNode extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  createProduct = () => {
    var formData = new FormData();

    formData.append("id", document.querySelector('#id').value);
    formData.append("name", document.querySelector('#name').value);
    formData.append("x", document.querySelector('#x').value);
    formData.append("y", document.querySelector('#y').value);

    axios.post('https://api.playpanzer.com/lumo/hermesvendor', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    window.location.replace("/admin/gnodes");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <br /><label>ID</label><br /><br /><input className="h2input" id="id" />
          <br /><label>Name</label><br /><br /><input className="h2input" id="name" />
          <br /><label>X</label><br /><br /><input className="h2input" id="x" />
          <br /><label>Y</label><br /><br /><input className="h2input" id="y" />
          <br />
          <button onClick={() => this.createProduct()}  className="button">Create Hermes Vendor</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
