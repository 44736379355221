/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Redirect,
  withRouter
} from 'react-router-dom';

import Heading from "./Heading";
import Topbar from "./Topbar";
import Navigation from "./Navigation";
import Home from "./Home";
import Login from "./LogIn";
import Signup from "./SignUp";
import SignupReferral from "./SignupReferral";
import CreateItem from "./CreateItem";
import CreateType from "./CreateType";
import CreateStoreItem from "./CreateStoreItem";
import CreatePersistentItem from "./CreatePersistentItem";
import CreateNPC from "./CreateNPC";
import CreateMob from "./CreateMob";
import CreateGatheringNode from "./CreateGatheringNode";
import CreateCraft from "./CreateCraft";
import CreateHermes from "./CreateHermes";
import EditItemType from "./EditItemType";
import EditHermes from "./EditHermes";
import EditNPC from "./EditNPC";
import EditMob from "./EditMob";
import EditGatheringNode from "./EditGatheringNode";
import EditCraft from "./EditCraft";
import Dashboard from "./Dashboard";
import AdminAffiliateDashboard from "./AdminAffiliateDashboard";
import AdminItemsDashboard from "./AdminItemsDashboard";
import AdminPersistentItemsDashboard from "./AdminPersistentItemsDashboard";
import AdminCraftingPlansDashboard from "./AdminCraftingPlansDashboard";
import AdminMapDashboard from "./AdminMapDashboard";
import AdminItemTypesDashboard from "./AdminItemTypesDashboard";
import AdminStoreDashboard from "./AdminStoreDashboard";
import AdminNPCDashboard from "./AdminNPCDashboard";
import AdminMobDashboard from "./AdminMobDashboard";
import AdminGatheringNode from "./AdminGatheringNode";
import AdminHermes from "./AdminHermes";
import EditStoreItem from "./EditStoreItem";
import AdminItemPreview from "./AdminItemPreview";
import AdminEditPreview from "./AdminEditPreview";
import AdminUserDashboard from "./AdminUserDashboard";
import AdminUserPreview from "./AdminUserPreview";
import Successful from "./Successful";
import Thanks from "./Thanks";
import AdminAdsEdit from "./AdminAdsEdit";
import AdminAdsDelete from "./AdminAdsDelete";
import RequestReset from "./RequestReset";
import CheckYourInbox from "./CheckYourInbox";
import ChangePassword from "./ChangePassword";
import Install from "./Install";
import Store from "./Store";
import Workshop from "./Workshop";
import LiveMap from "./LiveMap";
// import NotFoundPage from "./NotFoundPage";

function App() {
  return (
    <Router>
      <div>
        {/* <Heading /> */}
        {/* <Navigation /> */}
        {/* <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/store" component={Store} />
        <Route path="/workshop" component={Workshop} />
        <Route path="/refer" component={SignupReferral} />
        <Route path="/reset" component={RequestReset} />
        <Route path="/check-your-inbox" component={CheckYourInbox} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/admin/edit-itemtype" component={EditItemType} />
        <Route path="/admin/edit-npc" component={EditNPC} />
        <Route path="/admin/edit-mob" component={EditMob} />
        <Route path="/admin/edit-gnode" component={EditGatheringNode} />
        <Route path="/admin/edit-hermes" component={EditHermes} />
        <Route path="/admin/edit/store" component={EditStoreItem} />
        <Route path="/admin/edit/craft" component={EditCraft} />
        <Route path="/admin/map" component={LiveMap} />
        <Route path="/admin/create/npc" component={CreateNPC} />
        <Route path="/admin/create/mob" component={CreateMob} />
        <Route path="/admin/create/gnode" component={CreateGatheringNode} />
        <Route path="/admin/create/hermes" component={CreateHermes} />
        <Route path="/admin/create/persistent" component={CreatePersistentItem} />
        <Route path="/admin/create/store" component={CreateStoreItem} />
        <Route path="/admin/create/item" component={CreateItem} />
        <Route path="/admin/create/type" component={CreateType} />
        <Route path="/admin/create/craft" component={CreateCraft} />
        <Route path="/admin/affiliates" component={AdminAffiliateDashboard} />
        <Route path="/admin/types" component={AdminItemTypesDashboard} />
        <Route path="/admin/store" component={AdminStoreDashboard} />
        <Route path="/admin/hermes" component={AdminHermes} />
        <Route path="/admin/gnodes" component={AdminGatheringNode} />
        <Route path="/admin/economy" component={AdminItemsDashboard} />
        <Route path="/admin/persistent" component={AdminPersistentItemsDashboard} />
        <Route path="/admin/crafts" component={AdminCraftingPlansDashboard} />
        <Route path="/admin/npcs" component={AdminNPCDashboard} />
        <Route path="/admin/mobs" component={AdminMobDashboard} />
        <Route path="/admin/maps" component={AdminMapDashboard} />
        <Route path="/admin/item" component={AdminItemPreview} />
        <Route path="/admin/edit" component={AdminEditPreview} />
        <Route path="/admin/edit/ad" component={AdminAdsEdit} />
        <Route path="/admin/delete/ad" component={AdminAdsDelete} />
        <Route path="/admin/users" component={AdminUserDashboard} />
        <Route path="/admin/user" component={AdminUserPreview} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/thanks" component={Thanks} />
        <Route path="/successful" component={Successful} /> */}
        <Route path="/network" component={Thanks} />
        <Route path="/process" component={Thanks} />
        <Route exact path="/" component={Home} />
        {/* <Route component={NotFoundPage} /> */}
      </div>
    </Router>
  );
}

export default App;
