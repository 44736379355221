/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class Create extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  createProduct = () => {
    var formData = new FormData();

    formData.append("id", document.querySelector('#id').value);
    formData.append("type", document.querySelector('#type').value);
    formData.append("data", document.querySelector('#data').value);

    axios.post('https://api.playpanzer.com/lumo/persistent-item', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    window.location.replace("/admin/persistent");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <br /><label>ID</label><br /><br /><input className="h2input" id="id" />
          <br /><label>Type</label><br /><br /><input className="h2input" id="type" />
          <br /><label>Data</label><br /><br /><input className="h2input" id="data" />
          <br></br>
          <button onClick={() => this.createProduct()}  className="button">Create Item</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
