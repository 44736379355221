import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

export default class Home extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: null,
      data: null
    }
  }

  componentDidMount(){
    let id = window.location.pathname.split("/").pop();
    axios.get(`https://api.playpanzer.com/advert/delete/${id}`, {
      mode: 'no-cors', withCredentials: true, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }}).then((users) => {
        this.setState({ data: users.data });
        this.props.history.push('/admin/ads');
      });
  }

  render(){
    if(!this.state.user){
      return (
        <div>
        </div>
      );
    } else {
      return (
        <div></div>
      );
    }
  }
}

Home.propTypes = {
  history: PropTypes.object,
};
