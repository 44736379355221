/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class EditItemType extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  componentDidMount(){
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

    const apiKey = window.localStorage.getItem('lumokey');
        if(apiKey){
          axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
          }}).then((response, err) => {
            if(response.data.role != "admin"){
              this.props.history.push('/');
            }
            this.setState({ user: response.data})
            axios.get('https://api.playpanzer.com/lmitemtype/' + lastSegment, {
              mode: 'no-cors', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiKey}`
              }}).then((data) => {
                const typeData = data.data;

                document.querySelector('#name').value = typeData.name;
                document.querySelector('#description').value = typeData.description;
                document.querySelector('#type').value = typeData.type;
                document.querySelector('#slot').value = typeData.slot ? typeData.slot : 'null';
                document.querySelector('#rarity').value = typeData.rarity ? typeData.rarity : 'null';
                document.querySelector('#stats').value = typeData.stats ? typeData.stats : 'null';
                document.querySelector('#stackable').value = typeData.stackable ? typeData.stackable : 'null';

                this.setState({ data: typeData });
            }).catch((error)=>{
              return error;
            });
          }).catch(()=>{
              return;
          });
      } else {
          this.props.history.push('/login');
      }
  }

  update = () => {
    var formData = new FormData();

    const name = document.querySelector('#name').value;
    const description = document.querySelector('#description').value;
    const type = document.querySelector('#type').value;
    const slot = document.querySelector('#slot').value;
    const rarity = document.querySelector('#rarity').value;
    const stats = document.querySelector('#stats').value;
    const stackable = document.querySelector('#stackable').value;

    formData.append("name", name);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("slot", slot);
    formData.append("rarity", rarity);
    formData.append("stats", stats);
    formData.append("stackable", stackable);

    console.log(document.querySelector('#name').value);
    console.log(formData);

    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash
    axios.post('https://api.playpanzer.com/lumo/edit-itemtype/' + lastSegment, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    window.location.replace("/admin/types");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <br /><label>Name</label><br /><input className="h2input" id="name" />
          <br /><label>Description</label><br /><input className="h2input" id="description" />
          <br /><label>Type</label><br /><input className="h2input" id="type" />
          <br /><label>Slot</label><br /><input className="h2input" id="slot" />
          <br /><label>Rarity</label><br /><input className="h2input" id="rarity" />
          <br /><label>Base Stats</label><br /><input className="h2input" id="stats" />
          <br /><label>Stackable</label><br /><input className="h2input" id="stackable" />
          <br></br>
          <button onClick={() => this.update()}  className="button">Update Item Type</button>
        </div>
        <br /><br /><br />
    </div>)
  }
}
