import React from 'react';
import axios from 'axios';
// import 'react-datasheet/lib/react-datasheet.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { differenceInDays } from 'date-fns';

export default class AdminAffiliateDashboard extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user: null,
        views: null
      }
    }

    componentDidMount(){
      axios.get('https://api.playpanzer.com/panzer/user/info', {
        mode: 'no-cors', withCredentials: true, headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }}).then((response) => {
          this.setState({ user: response.data });

          axios.get('https://api.playpanzer.com/bbaff', {
            mode: 'no-cors', withCredentials: true, headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }}).then((users) => {
              this.setState({ data: users.data });

              axios.get('https://api.playpanzer.com/bbviews', {
                mode: 'no-cors', withCredentials: true, headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                }}).then((views) => {
                  this.setState({ views: views.data });
              }).catch((error)=>{
                return error;
              });
          }).catch((error)=>{
            return error;
          });
      }).catch((error)=>{
        return error;
      });
    }

    render(){
      var results = [];

      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <tr>
              <td>{data.brand}</td>
              <td>{data.identifier}</td>
              <td>{data.views}</td>
              <td>{data.conversions}</td>
            </tr>
          );
        });
      }

      if(this.state.user && this.state.views){
        const days = {
          0: {
            value: 0
          },
          1: {
            value: 0
          },
          2: {
            value: 0
          },
          3: {
            value: 0
          },
          4: {
            value: 0
          },
          5: {
            value: 0
          },
          6: {
            value: 0
          },
        };

        for(var i=0;i<7;i++){
          for(var j=0;j<Object.values(this.state.views).length;j++){
            const difference = differenceInDays(new Date(), new Date(Object.values(this.state.views)[j].created_on));

            if(days[difference]){
              days[difference].value += 1;
            }
          }
        }

        const data = [
          {name: '0', views: days[0].value},
          {name: '-1', views: days[1].value},
          {name: '-2', views: days[2].value},
          {name: '-3', views: days[3].value},
          {name: '-4', views: days[4].value},
          {name: '-5', views: days[5].value},
          {name: '-6', views: days[6].value}
        ];

        return(
          <div className="innerBody">
            <div className="card">
              <h2>Affiliates</h2>
              <br/><br/>
              <h4>Global Views</h4>
              <br/>
              <LineChart width={700} height={300} data={data} margin={{ top: 5, bottom: 5 }}>
                <Line type="monotone" dataKey="views" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
              </LineChart>
              <br/><br/>
              <table className="users">
                <tr>
                  <th>Brand Name</th>
                  <th>Reference</th>
                  <th>Views</th>
                  <th>Conversions</th>
                </tr>
                {results}
              </table>
            </div>
            <br/><br/><br/>
          </div>
        );
      }
      return(
        <div>
        </div>
      );
    }
}
