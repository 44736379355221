/* eslint-disable */

import React from 'react';
import MapFeed from './common/MapFeed';

import socketIOClient from "socket.io-client";
const ENDPOINT = "wss://lumo-waystone.herokuapp.com";

import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class LiveMap extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  render () {
    const socket = socketIOClient(ENDPOINT);

    var onevent = socket.onevent;
    socket.onevent = function (packet) {
      var args = packet.data || [];
      onevent.call (this, packet);    // original call
      packet.data = ["*"].concat(args);
      onevent.call(this, packet);      // additional call to catch-all
    };

    return(
      <div className="innerBody">
        <MapFeed socket={socket} />
      </div>
    );
  }
}
