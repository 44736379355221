/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class Create extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  createProduct = () => {
    var formData = new FormData();

    formData.append("name", document.querySelector('#name').value);
    formData.append("description", document.querySelector('#description').value);
    formData.append("slug", document.querySelector('#slug').value);
    formData.append("itemType", document.querySelector('#itemtype').value);
    formData.append("cost", document.querySelector('#cost').value);
    formData.append("currency", document.querySelector('#currency').value);
    formData.append("visible", document.querySelector('#visible').value);
    formData.append("featured", document.querySelector('#featured').value);
    formData.append("image", document.querySelector('#image').value);

    axios.post('https://api.playpanzer.com/lumo/store/new', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });

    window.location.replace("/admin/store");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
            <label>Name</label><br /><br /><input className="h2input" id="name" />
          <br /><br /><label>Description</label><br /><br /><input className="h2input" id="description" />
          <br /><br /><label>Slug</label><br /><br /><input className="h2input" id="slug" />
          <br /><br /><label>Item Type</label><br /><br /><input className="h2input" id="itemtype" />
          <br /><br /><label>Cost</label><br /><br /><input className="h2input" id="cost" />
          <br /><br /><label>Currency</label><br /><br /><input className="h2input" id="currency" />
          <br /><br /><label>Visible</label><br /><br /><input className="h2input" id="visible"/>
          <br /><br /><label>Featured</label><br /><br /><input className="h2input" id="featured" />
          <br /><br /><label>Image</label><br /><br /><input className="h2input" id="image" />
          <br></br>
          <button onClick={() => this.createProduct()}  className="button">Create New Store Item</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
