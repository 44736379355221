import React from 'react';
import {
  NavLink,
} from 'react-router-dom';

import axios from 'axios';

export default class Navigation extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        user: null,
        hide: false,
      }
    }

    componentDidMount(){
        const apiKey = window.localStorage.getItem('lumokey');
        if(apiKey){
            axios.get('https://api.playpanzer.com/panzer/user/info', {
            mode: 'no-cors', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiKey}`
            }}).then((response, err) => {
                this.setState({ user: response.data})
            }).catch(()=>{
                return;
            });
        }
    }

    render(){
        if(this.state.hide){
            return(
                <div style={{ display: 'block', position: 'absolute', top: '50px', }}>
                    {/* <a href="/"><img style={{ height: 50, marginTop: -10, float: 'left' }} src="https://tiny-cdn.s3-us-west-1.amazonaws.com/logos/billboard.png"></img></a> */}
                </div>
            );
        }

        // if(this.state.user == null){
            return(
              <div className="topbar">
                <div className="topbarInner">
                  {/* <div className="leftFloat">
                    <div className="branding">
                      <a href="/" class="company"><img src='https://f004.backblazeb2.com/file/elsewhere/sigil.png' class="wordmark" /></a>
                    </div>
                  </div>
                  <div className="rightFloat"> */}
                      <div className="navContainer">
                        <a class="navElement" href="#body">News & Updates</a>
                      </div>
                      <div className="navContainer">
                        <a href="http://wiki.playpanzer.com" className="navElement">Wiki</a>
                      </div>
                      <div className="navContainer">
                        <a href="https://forums.playpanzer.com" className="navElement">Forums</a>
                      </div>
                      <div className="navContainer">
                        <a class="navElement" href="https://discord.gg/dfXFmD6">Discord</a>
                      </div>
                      {/* <div className="navContainer">
                        <a class="navElement" href="/shop">Shop</a>
                      </div> */}
                      <div className="navContainer">
                        <a class="navElement" href="https://store.steampowered.com/app/1240920/PANZER/">Wishlist PANZER on Steam</a>
                      </div>
                  {/* </div> */}
                </div>
              </div>
            );
        // } else {
        //   return (
        //     <div className="topbar">
        //       <div className="topbarInner">
        //         <div className="leftFloat">
        //           <div className="branding">
        //             <a href="/" class="company"><img src='https://f004.backblazeb2.com/file/elsewhere/sigil.png' class="wordmark" /></a>
        //           </div>
        //         </div>
        //         <div className="rightFloat">
        //             <div className="navContainer">
        //               <a class="navElement" href="#body">News</a>
        //             </div>
        //             <div className="navContainer">
        //               <a href="http://wiki.playpanzer.com" className="navElement">Wiki</a>
        //             </div>
        //             <div className="navContainer">
        //               <a href="https://forums.playpanzer.com" className="navElement">Forums</a>
        //             </div>
        //             <div className="navContainer">
        //               <a class="navElement" href="https://discord.gg/dfXFmD6">Community</a>
        //             </div>
        //             <div className="authContainer">
        //               <a class="auth" href="/login">Wishlist</a>
        //             </div>
        //         </div>
        //       </div>
        //     </div>
        //   );
            // return(
            //     <div className="topbar">
            //         <div className="leftFloat">
            //           <div className="menu"></div>
            //           <img src='../assets/logo.png' class="company-logo" />

            //           <img src='../assets/wordmark.svg' class="company" />
            //           {/* <div className="company">
            //             LUMO
            //           </div> */}
            //         </div>
            //         <div className="rightFloat">
            //             <b className="playerCount">1 player online (greg)</b>
            //             <a class="auth" href="/dashboard">Dashboard</a>
            //             { this.state.user.role == "admin" ? (
            //                 <span>
            //                     <a class="auth" href="/admin/economy">Items</a>
            //                     <a class="auth" href="/admin/types">Types</a>
            //                     <a class="auth" href="/admin/maps">Maps</a>
            //                     <a class="auth" href="/store">Store</a>
            //                     <a class="auth" href="/workshop">Workshop</a>
            //                     <a class="auth" href="/admin/users">Users</a>
            //                 </span>
            //             ) : null}
            //             <a class="auth" onClick={() => { window.localStorage.clear('lumokey'); window.location.href = "https://playpanzer.com"}}>Logout</a>
            //             <a class="balance">
            //                 { this.state.user.username } ({this.state.user ? this.state.user.balance : 0} GOLD)
            //             </a>
            //         </div>
            //     </div>
            // );
            // return(
            //     <div style={{ display: 'block', position: 'absolute', top: '50px', width: '800px' }}>
            //         {/* <a href="/"><img style={{ height: 50, marginTop: -10, float: 'left' }} src="https://tiny-cdn.s3-us-west-1.amazonaws.com/logos/billboard.png"></img></a> */}
            //         <div style={{ float: 'right', fontFamily: 'Inter UI'}}>
            //             {/* <NavLink to="/dashboard" style={normalStyle} activeStyle={activeStyle}>Dashboard</NavLink> */}
            //             {/* { this.state.user.type == "staff" ? ( */}
            //                 <NavLink to="/admin/economy" style={normalStyle} activeStyle={activeStyle}>Economy</NavLink>
            //             {/* ) : null} */}
            //             {/* { this.state.user.type == "staff" ? ( */}
            //                 <NavLink to="/admin/types" style={normalStyle} activeStyle={activeStyle}>Items</NavLink>
            //                 <NavLink to="/admin/maps" style={normalStyle} activeStyle={activeStyle}>Maps</NavLink>
            //                 <NavLink to="/admin/users" style={normalStyle} activeStyle={activeStyle}>Users</NavLink>
            //             {/* ) : null} */}
            //             <a style={normalStyle} activeStyle={activeStyle} href="https://api.playpanzer.com/billboard/logout">Logout</a>
            //             {/* <NavLink to="/signup" style={normalStyle} activeStyle={activeStyle}>Sign Up</NavLink> */}
            //         </div>
            //     </div>
            // );
        // }
    }
}
