/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class AdminItemPreview extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  transfer = () => {
    const item = document.querySelector('#item').value;
    const user = document.querySelector('#user').value;

    axios.get(`https://api.playpanzer.com/lumo/transfer/${item}/${user}/bank`, {
        mode: 'no-cors', withCredentials: true, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }}).then((response) => {
        return;
    }).catch((error)=>{
      return error;
    });

    window.location.replace("/admin/economy");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <h2>Transfer Item</h2>
          <br /><label>Item ID</label><br /><br /><input className="h2input" id="item" />
          <br /><label>User</label><br /><br /><input className="h2input" id="user" />
          <br></br>
          <button onClick={() => this.transfer()}  className="button">Transfer</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
