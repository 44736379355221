/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class Create extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  createItemType = () => {
    var formData = new FormData();

    formData.append("name", document.querySelector('#name').value);
    formData.append("description", document.querySelector('#description').value);
    formData.append("slug", document.querySelector('#slug').value);
    formData.append("type", document.querySelector('#type').value);
    formData.append("slot", document.querySelector('#slot').value);
    formData.append("rarity", document.querySelector('#rarity').value);
    formData.append("stats", document.querySelector('#stats').value);
    formData.append("stackable", document.querySelector('#stackable').value);

    axios.post('https://api.playpanzer.com/lumo/type', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    window.location.replace("/admin/types");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">

        <label>Name</label><br /><br />
        <input className="h2input" id="name" />
        <br /><br />

        <label>Description</label><br /><br />
        <input className="h2input" id="description" />
        <br /><br />

        <label>Slug</label><br /><br />
        <input className="h2input" id="slug" />

        <br /><br /><label>Type</label><br /><br />
        <input className="h2input" id="type"/>

        <br /><br /><label>Slot</label><br /><br />
        <input className="h2input" id="slot" />

        <br /><br /><label>Rarity</label><br /><br />
        <select id="rarity" className="h2input" name="rarity">
          <option value="standard">Standard</option>
          <option value="common">Common</option>
          <option value="uncommon">Uncommon</option>
          <option value="rare">Rare</option>
          <option value="legendary">Legendary</option>
          <option value="heirloom">Heirloom</option>
        </select>


        <br /><br /><label>Stats</label><br /><br />
        <input className="h2input" id="stats" />


        <br /><br /><label>Stackable</label><br /><br />
        <input className="h2input" id="stackable" />

        <br></br>

        <button onClick={() => this.createItemType()}  className="button">Create New Type</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
