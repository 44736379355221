/* eslint-disable */

import React from 'react';
import '../styles/style.scss';
import axios from 'axios';
export default class Create extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  componentDidMount(){
    let id = window.location.pathname.split("/").pop();
    axios.get(`https://api.playpanzer.com/advert/fetch/${id}`, {
        mode: 'no-cors', withCredentials: true, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }}).then((response) => {
        this.setState({ data: response.data})
    }).catch((error)=>{
      return error;
    });
  }

  createProduct = () => {
    var formData = new FormData();

    formData.append("title", document.querySelector('#title').value);
    formData.append("subtitle", document.querySelector('#subtitle').value);
    formData.append("image", document.querySelector('#image').value);
    formData.append("limit", document.querySelector('#limit').value);
    formData.append("author", document.querySelector('#author').value);
    formData.append("link", document.querySelector('#link').value);
    formData.append("cpm", document.querySelector('#cpm').value);
    formData.append("affiliate", document.querySelector('#affiliate').value);

    let id = window.location.pathname.split("/").pop();

    axios.post(`https://api.playpanzer.com/advert/edit/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    this.props.history.push('/admin/ads');
  }

  render () {
    if(this.state.data){
      return(<div className="innerBody">
          <div className="card">
          <label>Author</label><br /><input className="h2input" placeholder={this.state.data.author} id="author" />
          <br /><label>Title</label><br /><input className="h2input" placeholder={this.state.data.title} id="title" />
          <br /><label>Subtitle</label><br /><input className="h2input" placeholder={this.state.data.subtitle} id="subtitle" />
          <br /><label>Image URL</label><br /><input className="h2input" placeholder={this.state.data.image} id="image"/>
          <br /><label>Link</label><br /><input className="h2input" placeholder={this.state.data.link} id="link" />
          <br /><label>Limit</label><br /><input className="h2input" placeholder={this.state.data.limit} id="limit" />
          <br /><label>CPM</label><br /><input className="h2input" placeholder={this.state.data.cpm} id="cpm" />
          <br /><label>Affiliate</label><br /><input className="h2input" placeholder={this.state.data.affiliate} id="affiliate" />
          <br></br>
          <button onClick={() => this.createProduct()}  className="button">Create advert</button>
          </div>
          <br /><br /><br /><br /><br />
      </div>)
    } else {
      return <div></div>;
    }
  }
}
