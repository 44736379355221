import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../styles/style.scss';

// Since this component is simple and static, there's no parent container for it.
export default class Signup extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: null
    }
  }

  componentDidMount(){
    axios.get('https://api.playpanzer.com/panzer/user/info', {
        mode: 'no-cors', withCredentials: true, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }}).then((response, err) => {
        if(err){
          return;
        } else {
          this.props.history.push('/dashboard');
        }
        this.setState({ user: response.data})
    }).catch(()=>{
      return;
    });
  }

  render(){
    return (
      <div className="innerBody">
        <form action="https://api.playpanzer.com/lumo/signup" method="post" style={{ width: 700 }}>
          <h2 style={{ marginBottom: 25 }}>Sign Up</h2>
          <br /><br />
          <div className="inputs">
            <input className="form" style={{ width: 300 }} placeholder="Email Address" type="email" size="30" name="email" aria-required="true"></input>
            <input className="form" style={{ width: 300 }} placeholder="Password" type="password" size="30" name="password" aria-required="true"></input>
          </div>
          <button type="submit" style={{ float: 'right', marginRight: 85}}>Create your Lumo account</button>
        </form>
      </div>
    );
  }
}

Signup.propTypes = {
  history: PropTypes.object,
};
