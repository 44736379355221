import React from 'react';
import { NavLink } from 'react-router-dom';
import Masonry from 'react-masonry-css'

import axios from 'axios';

export default class Store extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user: null,
      }
    }

    componentDidMount(){
      const apiKey = window.localStorage.getItem('lumokey');
      axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${apiKey}`
        }}).then((response) => {
          axios.get('https://api.playpanzer.com/lmstore', {
            mode: 'no-cors', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiKey}`
          }}).then((store) => {
            console.log(store);
            this.setState({ user: response.data, data: store.data })
          });
      }).catch((error)=>{
        return error;
      });
    }

    render(){
      var results = [];
      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <NavLink to={'/project/' + data._id}>
              <div className="store-item">
                { data.image ? (<img className="store-image center" src={data.image} />) : null }
                <h1 className="store-name center">{data.name}</h1>
                <div className="store-item-footer">
                  <div className="store-item-footer-text">Buy</div>
                </div>
              </div>
            </NavLink>
          );
        });
      }

      if(this.state.user){
        // console.log(this.state.billboard);
        return(
          <div className="store-body">
            <Masonry
              breakpointCols={4}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {results}
            </Masonry>
          </div>
        );
      }
      return(
        <div></div>
      );
    }
}
