import React from 'react';
import {
  NavLink,
} from 'react-router-dom';

import axios from 'axios';

export default class Navigation extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        user: null,
        hide: false,
      }
    }

    componentDidMount(){
      const apiKey = window.localStorage.getItem('lumokey');
      if(apiKey){
          axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
          }}).then((response, err) => {
              console.log(response.data);
              this.setState({ user: response.data})
          }).catch(()=>{
              return;
          });
      }
    }

    render(){
      return (
        <div className="headbar">
          {/* <img className="company" src='https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z765ce57e5f60dfd68b4c0614_f115b859fb065d33b_d20240324_m131748_c004_v0402019_t0028_u01711286268432' /> */}
        </div>
      );
    }
}
