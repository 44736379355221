/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class EditNPC extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  componentDidMount(){
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

    console.log(lastSegment);
    document.querySelector('#id').value = lastSegment;

    const apiKey = window.localStorage.getItem('lumokey');
        if(apiKey){
          axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
          }}).then((response, err) => {
            if(response.data.role != "admin"){
              this.props.history.push('/');
            }
            this.setState({ user: response.data})
            axios.get('https://api.playpanzer.com/lmnpc/' + lastSegment, {
              mode: 'no-cors', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${apiKey}`
              }}).then((data) => {
                const npcData = data.data;

                document.querySelector('#name').value = npcData.name;
                document.querySelector('#level').value = npcData.level;
                document.querySelector('#type').value = npcData.type;
                document.querySelector('#subtype').value = npcData.subtype;
                document.querySelector('#model').value = npcData.model;
                document.querySelector('#location').value = npcData.location ? npcData.location : '';
                document.querySelector('#quests').value = npcData.quests ? npcData.quests : '';
                document.querySelector('#data').value = npcData.data;

                this.setState({ data: npcData });
            }).catch((error)=>{
              return error;
            });
          }).catch(()=>{
              return;
          });
      } else {
          this.props.history.push('/login');
      }
  }

  createProduct = () => {
    var formData = new FormData();

    formData.append("id", document.querySelector('#id').value);
    formData.append("name", document.querySelector('#name').value);
    formData.append("level", document.querySelector('#level').value);
    formData.append("type", document.querySelector('#type').value);
    formData.append("subtype", document.querySelector('#subtype').value);
    formData.append("model", document.querySelector('#model').value);
    formData.append("location", document.querySelector('#location').value);
    formData.append("quests", document.querySelector('#quests').value);
    formData.append("data", document.querySelector('#data').value);

    axios.post('https://api.playpanzer.com/lumo/update-npc', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    window.location.replace("/admin/npcs");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <br /><label>ID</label><br /><input className="h2input" id="id" />
          <br /><label>Name</label><br /><input className="h2input" id="name" />
          <br /><label>Level</label><br /><input className="h2input" id="level" />
          <br /><label>Type</label><br /><input className="h2input" id="type" />
          <br /><label>Subtype</label><br /><input className="h2input" id="subtype" />
          <br /><label>Model</label><br /><input className="h2input" id="model" />
          <br /><label>Location</label><br /><input className="h2input" id="location" />
          <br /><label>Quests</label><br /><input className="h2input" id="quests" />
          <br /><label>Data</label><br /><input className="h2input" id="data" />
          <br></br>
          <button onClick={() => this.createProduct()}  className="button">Update NPC</button>
        </div>
        <br /><br /><br />
    </div>)
  }
}
