import React from 'react';
import '../styles/style.scss';

class Project extends React.Component {
    constructor(props){
      super(props);
    }

    componentDidMount() {
    // Redirect to the main website after a short delay
      window.location.href = 'https://tally.so/r/wa0ZBZ';
    }

    render () {
        return(<div>
            {/* <h1 className="h2div" style={{ width: 500 }} disabled placeholder="Title">Thanks for the support</h1> */}
            {/* <h3 className="h3div" disabled placeholder="Description">We will contact you when we release new updates</h3> */}
        </div>)
    }
}

export default Project;
