import React from 'react';
import axios from 'axios';
// import 'react-datasheet/lib/react-datasheet.css';
import { Eye } from 'react-feather';
import { differenceInDays } from 'date-fns';

export default class UserAdminDashboard extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user : null,
      }
    }


    componentDidMount(){
      const apiKey = window.localStorage.getItem('lumokey');
      if(apiKey){
        axios.get('https://api.playpanzer.com/panzer/user/info', {
        mode: 'no-cors', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiKey}`
        }}).then((response, err) => {
          if(response.data.role != "admin"){
            this.props.history.push('/');
          }
          this.setState({ user: response.data})
          axios.get('https://api.playpanzer.com/lmusers', {
            mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
            }}).then((users) => {
              this.setState({ data: users.data });
          }).catch((error)=>{
            return error;
          });
        }).catch(()=>{
            return;
        });
      } else {
        this.props.history.push('/login');
      }
    }

    render(){
      var results = [];

      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <tr>
              <td>{data.email_address}</td>
              <td>{data.username}</td>
              <td>{data.api_key}</td>
              <td>{data.balance}</td>
              <td>{differenceInDays(new Date(), new Date(data.created_on))} days ago</td>
              <td>
                <a href={`/admin/user/${data.id}`}>
                  <div className="viewButton">
                    <Eye color="white" size={24} />
                  </div>
                </a>
              </td>
            </tr>
          );
        });
      }

      if(this.state.user){
        return(
          <div className="innerBody">
            <div className="card">
              <h2 className="pageHeader">Users</h2>
              <table className="users">
                <tr>
                  <th>Email Address</th>
                  <th>Username</th>
                  <th>Key</th>
                  <th>Balance</th>
                  <th>Creation</th>
                  <th>Actions</th>
                </tr>
                {results}
              </table>
            </div>
            <br/><br/><br/>
          </div>
        );
      }
      return(
        <div>
        </div>
      );
    }
}
