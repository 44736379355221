import React from 'react';
import { NavLink } from 'react-router-dom';

import axios from 'axios';

export default class Dashboard extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user: null,
      }
    }

    componentDidMount(){
      const apiKey = window.localStorage.getItem('lumokey');
      axios.get('https://api.playpanzer.com/panzer/user/info', {
          mode: 'no-cors', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${apiKey}`
        }}).then((response) => {
          axios.get('https://api.playpanzer.com/lumo/stats', {
            mode: 'no-cors', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiKey}`
          }}).then((statistics) => {
            this.setState({ user: response.data, data: statistics.data })
          });
      }).catch((error)=>{
        return error;
      });
    }

    render(){
      var results = [];
      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <div>
              <NavLink to={'/project/' + data.identifier}>
                <div className="product">
                  <div className="product-container">
                    <p className="product-name">{data.name}</p>
                  </div>
                </div>
              </NavLink>
              <br/>
            </div>
          );
        });
      }

      if(this.state.user){
        // console.log(this.state.billboard);
        return(
          <div className="innerBody">
            { this.state.user.username == "greg" && this.state.data ? (
              <div className="card">
                <h2 className="pageHeader">Statistics</h2>
                <h3>Total users: {this.state.data.users}</h3>
                <h3>Total maps: {this.state.data.maps}</h3>
                <h3>Total store items: {this.state.data.store_items}</h3>
                <h3>Total items: {this.state.data.items}</h3>
                <h3>Total circulation: {this.state.data.circulation}</h3>
                <h3>Total lifetime circulation: {this.state.data.lifetimecirculation}</h3>
                <br/>
              </div>
            ) : (
              <div className="card">
                <h2>Your Balance</h2>
                <h3>{this.state.user ? this.state.user.balance : 0} credits</h3>
                <br/>
              </div>
            )}
            <br/><br/><br/><br/><br/>

            <div className="products">{results}</div>
          </div>
        );
      }
      return(
        <div></div>
      );
    }
}
