/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class AdminItemPreview extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  update = () => {
    const item = document.querySelector('#item').value;
    // const user = document.querySelector('#user').value;

    axios.get(`https://api.playpanzer.com/lumo/edit/${item}`, {
        mode: 'no-cors', withCredentials: true, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }}).then((response) => {
        return;
    }).catch((error)=>{
      return error;
    });

    window.location.replace("/admin/economy");
  }

  render () {
    return(
      <div className="innerBody">
        <div className="card">
          <h2>Edit</h2>
          <br /><label>Name</label><br /><br /><input className="h2input" id="name" />
          <br /><label>Type</label><br /><br /><input className="h2input" id="type" />
          <br /><label>Slot</label><br /><br /><input className="h2input" id="slot" />
          <br></br>
          <button onClick={() => this.update()}  className="button">Update Item</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
