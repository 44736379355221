/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class Create extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  render () {
    return(<div>
        <h2>Thanks for your purchase!</h2>
        <h3>We've emailed you download links for the files you've just brought.</h3>
        <img style={{ width: 300, display: 'block', margin: '0 auto', marginTop: 55, marginBottom: 55 }} alt="Tiny" src='https://i.imgur.com/lkIGzak.png' />
        <h3>Be sure to check your spam folder if it's not in your inbox yet.</h3>
    </div>)
  }
}
