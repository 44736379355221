import React from 'react';
import PropTypes from 'prop-types';

export default class Referrals extends React.Component {
    constructor(props){
      super(props);
      this.state = { 
        user: null,
        data: null,
      }
    }

    render(){
      return(
        <div className="innerBody">
          <div className="card">
            <h2>Instructions</h2>
            <br />
            <h4>■ Download <a href="https://chrome.google.com/webstore/detail/billboard/fcieajnopahnnkookdgcphnkkmhkhmfk">the extension</a> from the Chrome Store</h4>
            <br />
            <h4>■ Paste the API key into your extension and click Save</h4>
            <br />
            <h4>■ {`You're ready to go`}</h4>
          </div>
        </div>
      );
    }
}

Referrals.propTypes = {
  history: PropTypes.object,
};