import React, { useRef, useEffect, useState } from "react";
import { getDatasetAtEvent, Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

function containsObject(array, object) {
  return array.some((e) => Object.entries(e).toString() === Object.entries(object).toString())
}

export default function MapFeed({ socket }) {
  const chartRef = useRef(null);

  const [response, setResponse] = useState("");
  const [responseAmount, setResponseAmount] = useState(0);
  const [dataset, setDataset] = useState([{ x:0, y:0 }]);

  useEffect(() => {
    socket.on("map", data => {
      console.log("MAP UPDATE RECEIVED " + responseAmount);

      setResponse(data);
      setResponseAmount(responseAmount + 1);

      const message = JSON.parse(data).message;
      const newElement = JSON.parse(message);

      const newDataset = dataset;

      if(containsObject(dataset, newElement)){
        console.log("DUPLICATE");
      } else {
        newDataset.push(JSON.parse(message));
        setDataset(newDataset);
      }
    });
  });

  if (chartRef.current) {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }
  }

  return (
    <p>
      <div>
        Updates: ({responseAmount})
      </div>
      <Scatter
        ref={chartRef}
        redraw={true}
        data={{
          datasets: [{ label: 'Map', data: dataset, backgroundColor: 'rgb(255, 99, 132)' }]
        }}
      />
    </p>
  );
}
