/* eslint-disable */

import React from 'react';
import axios from 'axios';
import '../styles/style.scss';
// Since this component is simple and static, there's no parent container for it.
export default class Create extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
    }
  }

  componentDidMount(){
    let id = window.location.pathname.split("/").pop();
    axios.get(`https://api.playpanzer.com/lumo/store/fetch/${id}`, {
        mode: 'no-cors', withCredentials: true, headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }}).then((response) => {
        this.setState({ data: response.data})
    }).catch((error)=>{
      return error;
    });
  }


  createProduct = () => {
    var formData = new FormData();

    let id = window.location.pathname.split("/").pop();

    formData.append("name", document.querySelector('#name').value);
    formData.append("description", document.querySelector('#description').value);
    formData.append("slug", document.querySelector('#slug').value);
    formData.append("itemType", document.querySelector('#itemtype').value);
    formData.append("cost", document.querySelector('#cost').value);
    formData.append("currency", document.querySelector('#currency').value);
    formData.append("visible", document.querySelector('#visible').value);
    formData.append("featured", document.querySelector('#featured').value);
    formData.append("image", document.querySelector('#image').value);

    axios.post(`https://api.playpanzer.com/lumo/store/edit/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });

    window.location.replace("/admin/store");
  }

  render () {
    if(this.state.data == null){
      return null;
    }

    return(
      <div className="innerBody">
        <div className="card">
            <label>Name</label><br /><br /><input className="h2input" id="name" placeholder={this.state.data.name} />
          <br /><br /><label>Description</label><br /><br /><input className="h2input" id="description" placeholder={this.state.data.description} />
          <br /><br /><label>Slug</label><br /><br /><input className="h2input" id="slug" placeholder={this.state.data.name} />
          <br /><br /><label>Item Type</label><br /><br /><input className="h2input" id="itemtype" placeholder={this.state.data.itemtype} />
          <br /><br /><label>Cost</label><br /><br /><input className="h2input" id="cost" placeholder={this.state.data.cost} />
          <br /><br /><label>Currency</label><br /><br /><input className="h2input" id="currency" placeholder={this.state.data.currency} />
          <br /><br /><label>Visible</label><br /><br /><input className="h2input" id="visible" placeholder={this.state.data.visible} />
          <br /><br /><label>Featured</label><br /><br /><input className="h2input" id="featured" placeholder={this.state.data.featured} />
          <br /><br /><label>Image</label><br /><br /><input className="h2input" id="image" placeholder={this.state.data.image} />
          <br></br>
          <button onClick={() => this.createProduct()}  className="button">Edit Store Item</button>
        </div>
        <br /><br /><br /><br /><br />
    </div>)
  }
}
