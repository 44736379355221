import React from 'react';
import axios from 'axios';
// import 'react-datasheet/lib/react-datasheet.css';
import { differenceInDays } from 'date-fns';

export default class UserAdminDashboard extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        data: null,
        user : null,
      }
    }


    componentDidMount(){
      const apiKey = window.localStorage.getItem('lumokey');
      if(apiKey){
        axios.get('https://api.playpanzer.com/panzer/user/info', {
        mode: 'no-cors', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${apiKey}`
        }}).then((response, err) => {
          if(response.data.role != "admin"){
            this.props.history.push('/');
          }
          this.setState({ user: response.data})
          axios.get('https://api.playpanzer.com/lmmaps', {
            mode: 'no-cors', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
            }}).then((users) => {
              this.setState({ data: users.data });
          }).catch((error)=>{
            return error;
          });
        }).catch(()=>{
            return;
        });
      } else {
        this.props.history.push('/login');
      }
    }

    render(){
      var results = [];

      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map(function(data){
          results.push(
            <tr>
              <td>{data.seed}</td>
              <td>{data.owner ? data.owner : null}</td>
              <td>{data.type ? data.type : null}</td>
              <td>{data.visits ? data.visits : null}</td>
              <td>{data.created_on ? `${differenceInDays(new Date(), new Date(data.created_on))} days ago` : null}</td>
              <td>{data.last_updated ? `${differenceInDays(new Date(), new Date(data.last_updated))} days ago` : null}</td>
            </tr>
          );
        });
      }

      if(this.state.user){
        return(
          <div className="innerBody">
            <div className="card">
              <h2 className="pageHeader">Maps</h2>
              <table className="users">
                <tr>
                  <th>Seed</th>
                  <th>Owner</th>
                  <th>Type</th>
                  <th>Visits</th>
                  <th>Creation</th>
                  <th>Updated</th>
                </tr>
                {results}
              </table>
            </div>
            <br/><br/><br/>
          </div>
        );
      }
      return(
        <div>
        </div>
      );
    }
}
